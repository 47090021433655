<template>
  <div class="emissions-by-category-wrapper">
    <div class="chart">
      <chart
        :axisYByCategory="axisYByCategory"
        :chartData="chartData"
        :chartDataCompare="chartDataCompare"
        :selectedTab="selectedTab"
        :branchIds="branchIds"
        @selectedCategory="selectedCategory($event)"
        @onSelectCategory="onSelectCategory"
        @updateCompareEmissionsByCategory="updateCompareEmissionsByCategory"
        :filterUpdatedKeys="filterUpdatedKeys"
        :toggleCallApiGetChart="toggleCallApiGetChart"
      ></chart>
    </div>

    <div class="chart-detail">
      <chart-detail
        :branchIds="branchIds"
        :selectedTab="selectedTab"
        :emissionsByCategoryDetailTop5="emissionsByCategoryDetailTop5"
        :emissionsByCategoryDetail="emissionsByCategoryDetail"
        :emissionsByCategoryCompareDetail="emissionsByCategoryCompareDetail"
        :isGetAllFilter="isGetAllFilter"
        :isShowButton="isShowButton"
      ></chart-detail>
    </div>

    <div class="dialog-chart-detail">
      <chart-detail-dialog
        :dialog="isShowChartDetailDialog"
        @close="closeDialog"
        :isShowDetailButton="isShowButton"
        :emissionsByCategoryDetail="emissionsByCategoryDetail"
        :selectedTab="selectedTab"
      >
        <chart-detail
          :branchIds="branchIds"
          :selectedTab="selectedTab"
          :emissionsByCategoryDetailTop5="emissionsByCategoryDetailTop5"
          :emissionsByCategoryDetail="emissionsByCategoryDetail"
          :emissionsByCategoryCompareDetail="emissionsByCategoryCompareDetail"
          :isGetAllFilter="isGetAllFilter"
          :isShowButton="isShowButton"
          :isDialog="true"
        ></chart-detail>
      </chart-detail-dialog>
    </div>
  </div>
</template>

<style lang="scss">
.emissions-by-category-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.chart {
  flex: 0 0 100%;
}
.chart-detail {
  display: none;
}

@media only screen and (min-width: 480px) {
  .chart {
    flex: 0 0 73%;
  }
}
@include desktop {
  .chart-detail {
    display: block;
  }
}
</style>

<script>
import { mapActions, mapState } from 'vuex';
import Chart from '@/views/dashboard/components/emissions-by-category/emissions-category-chart/chart/index.vue';
import ChartDetail from '@/views/dashboard/components/emissions-by-category/emissions-category-chart/detail-chart/index.vue';
import ChartDetailDialog from '@/views/dashboard/components/emissions-by-category/emissions-category-chart/detail-chart-dialog/index.vue';
import { getEmissionsByCategory, getEmissionByCategoryDetailTop5 } from '@/api/dashboard';
import { prepareChartAxisY, calcCategoryPercent } from '@/concerns/chart';
import { scope1Tooltip, scope2Tooltip, scope3Tooltip } from '@/store/dashboard';
import { defaultEmissionByCategory } from '@/constants/dashboard';
import { getTotalEmissionValue } from '@/utils/getTextWithCondition';
export default {
  components: { Chart, ChartDetail, ChartDetailDialog },
  data() {
    return {
      isShowChartDetailDialog: false,
      windowWidth: window.outerWidth,
      chartData: [],
      chartDataCompare: {
        scope1: [],
        scope2: [],
        scope3: [],
        scope4: [],
      },
      axisYByCategory: {},
      emissionsByCategoryDetail: {},
      emissionsByCategoryCompareDetail: {},
      emissionsByCategoryDetailTop5: [],
      minValMain: null,
      maxValMain: null,
      dataMain: {},
      isShowButton: true,
      dataCache: {},
      dataTop5Cache: {},
      isChangeType: false,
      dataCacheCompare: {},
      toggleCallApiGetChart: false,
    };
  },
  props: {
    startMonth: {
      type: Number,
      default: 3,
    },
    branchIds: {
      type: Array,
      default: () => [],
    },
    selectedTab: {
      type: Number,
      default: 0,
    },
    filterUpdatedKeys: {
      type: Number,
      default: 0,
    },
    isGetAllFilter: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: true,
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDestroy() {
    if (!window) {
      return;
    }
    window.removeEventListener('resize', this.onResize);
  },
  computed: {
    ...mapState('dashboard', ['isEnableScope3']),
    ...mapState('settingsDashboard', ['itemsSettings']),
    isShowChartDetailYearly() {
      return this.windowWidth >= 1366;
    },
    watchData() {
      return this.isLoading + this.selectedTab + this.branchIds + this.filterUpdatedKeys + this.isEnableScope3;
    },
    getType() {
      return this.itemsSettings[this.$store.state.userData.contractor]?.type || 'market';
    }
  },
  watch: {
    watchData: {
      handler() {
        if (this.isLoading) return;
        this.getChartData();
      },
      deep: true,
    },
    chartData: {
      handler(val) {
        if (val.length === 0) {
          // reset the chart detail to the initial
          this.emissionsByCategoryDetail = {};
          this.emissionsByCategoryCompareDetail = {};
          this.emissionsByCategoryDetailTop5 = [];
        }
      }
    },
    itemsSettings: {
      handler(newVal, oldVal) { 
        const oldType = oldVal[this.$store.state.userData.contractor]?.type || 'market';
        const newType = newVal[this.$store.state.userData.contractor]?.type;
        if (newType !== oldType) {
          this.isChangeType = true;  // Set the flag to true when changing type
          this.handleTypeChange(newType);
          // Reset where the type change completes
          setTimeout(() => {
            this.isChangeType = false;
          }, 0); 
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('dashboard', ['updateSelectedEmissionsByCategoryDashboard']),
    onResize() {
      this.windowWidth = window.outerWidth;
    },
    closeDialog() {
      this.isShowChartDetailDialog = false;
    },
    selectedCategory(_) {
      this.isShowChartDetailDialog = true;
    },
    getParamsDurationOfStore() {
      let paramsStore = {};
      switch (this.selectedTab) {
        case 0:
          paramsStore.year = this.$store.state.dashboard.selectedEmissionsByCategoryDashboard.selectedYear?.year;
          break;
        case 1:
          const oldHalfYearSelected = this.$store.state.dashboard.selectedEmissionsByCategoryDashboard.selectedHalfYear;
          paramsStore.year = oldHalfYearSelected?.year;
          paramsStore.order = oldHalfYearSelected?.order;
          break;
        case 2:
          const oldQuarterYearSelected =
            this.$store.state.dashboard.selectedEmissionsByCategoryDashboard.selectedQuarterYear;
          paramsStore.order = oldQuarterYearSelected?.order;
          paramsStore.year = oldQuarterYearSelected?.year;
          break;
        case 3:
          const oldMonthYearSelected =
            this.$store.state.dashboard.selectedEmissionsByCategoryDashboard.selectedMonthYear;
            const [oldYear, oldMonth] = oldMonthYearSelected?.selected_range?.split('/');
          paramsStore.month = parseInt(oldMonth);
          paramsStore.year = oldYear;
          break;
        default:
          paramsStore.year = this.$store.state.dashboard.selectedEmissionsByCategoryDashboard.selectedYear?.year;
          break;
      }
      return paramsStore;
    },
    handleTypeChange(newType) {
      if (this.dataCache[newType]) {
        // update the data chart
        this.handleDataUpdate(newType);
      }
      if (this.dataCacheCompare[newType]) {
        // update the data chart compare
        this.handleDataCompareUpdate(newType);
      }
      if (this.dataTop5Cache[newType]) {
        // update top 5 emission
        this.emissionsByCategoryDetailTop5 = this.dataTop5Cache[newType].result;
      }
    },
    handleDataUpdate(type) {
      const res = this.dataCache[type];
      const [data, maxVal, minVal, totalList] = this.prepareChartData(res, this.selectedTab);
      this.minValMain = minVal;
      this.maxValMain = maxVal;
      this.dataMain = { ...data };
      const [values, min, max] = prepareChartAxisY({totalList: totalList});
      this.axisYByCategory = {
        values,
        min,
        max,
      };
      const results = Object.keys(data).map((scopeName) => this.preparePercent(scopeName, data[scopeName], max, min));
      this.chartData = [];
      this.chartData.push(...results[0], ...results[1]);
      results[2].forEach((data) => this.chartData.push(data));
      this.chartData.push(...results[3]);
      if (!this.isEnableScope3) {
        this.chartData = this.chartData.filter((v) => v.scope && v.scope !== 3);
      }
    },
    handleDataCompareUpdate(type) {
      const res = this.dataCacheCompare[type];
      let [dataCompare, maxVal, minVal, totalList] = this.prepareChartData(res, this.selectedTab); // 0: duration, 1: month
      if (maxVal < this.maxValMain) {
        maxVal = this.maxValMain;
      }

      if (minVal > this.minValMain) {
        minVal = this.minValMain;
      }

      const [values, min, max] = prepareChartAxisY({totalList: totalList});
      this.axisYByCategory = {
        values,
        min,
        max,
      };
      //calculate the difference
      const resultsCompare = Object.keys(dataCompare).map((scopeName) =>
        this.preparePercent(scopeName, dataCompare[scopeName], max, min),
      );
      //calculate the main
      const resultsMain = Object.keys(this.dataMain).map((scopeName) =>
        this.preparePercent(scopeName, this.dataMain[scopeName], max, min),
      );
      this.chartData = [];
      // Combine and push scope1 data
      this.chartData.push(...this.combineData(resultsMain[0], resultsCompare[0]));

      // Combine and push scope2 data
      this.chartData.push(...this.combineData(resultsMain[1], resultsCompare[1]));

      // Combine and push scope3 data
      resultsMain[2].forEach((data, index) => {
        this.chartData.push({ ...data, pastDurations: resultsCompare[2][index] || {} });
      });

      // Combine and push scope4 data
      this.chartData.push(...this.combineData(resultsMain[3], resultsCompare[3]));

      if (!this.isEnableScope3) {
        this.chartData = this.chartData.filter((v) => v.scope && v.scope !== 3);
      }
    },
    async getChartData() {
      const paramsStore = this.getParamsDurationOfStore();
      if(!paramsStore.year) {
        return;
      }
      let params = {
        type: this.selectedTab === 1 || this.selectedTab === 2 ? this.selectedTab + 1 : this.selectedTab === 3 ? 1 : 0,
        branch_id: this.branchIds,
        contractor_id: this.$store.state.userData.contractor,
        ...paramsStore,
      };
      await getEmissionsByCategory(params)
        .then((res) => {
          this.dataCache= res;
          this.handleDataUpdate(this.getType);
          this.toggleCallApiGetChart = !this.toggleCallApiGetChart;
        })
        .catch((err) => {
          console.warn(err);
        });
    },
    onSelectCategory($event, isTablet = false, hasCompare = false, dataCompare = undefined, index) {
      this.isShowButton = true;
      if (isTablet) {
        this.isShowChartDetailDialog = true;
      }
      
      let params = {
        type: this.selectedTab === 1 || this.selectedTab === 2 ? this.selectedTab + 1 : this.selectedTab === 3 ? 1 : 0,
        branch_id: this.branchIds,
        row_num: $event.original?.row_num,
        ...this.getParamsDurationOfStore(),
      };
      if(index != null && index >= 0) {
        params.type_credit_id = $event.credit[index].type_credit_id
        this.emissionsByCategoryDetail =  $event.credit[index]
      } else {
        this.emissionsByCategoryDetail = $event;
      }
      if(dataCompare) {
        this.emissionsByCategoryCompareDetail = dataCompare;
      }
      if ($event?.type_credit_id) {
        this.isShowButton = false;
        this.emissionsByCategoryDetailTop5 = []; // TODO: this is credit and is not show top 5 details
        return;
      }
      if (!this.isChangeType) {
        Promise.all([ this.getEmissionDetailTop15(params, $event)]);
      }
    },
    getEmissionDetailTop15(params, item) {
      getEmissionByCategoryDetailTop5(params)
        .then((res) => {
          this.dataTop5Cache = res;
          this.emissionsByCategoryDetailTop5 = res[this.getType].result;
        })
        .catch((err) => {
          console.warn(err);
        });
    },
    prepareChartData(data, type) {

      const result = defaultEmissionByCategory;
      const values = [];
      // scope 1
      const scope1Input = data.scope_1; // Object
      values.push(getTotalEmissionValue(this.selectedTab, scope1Input));
      result.scope1 = {
        [scope1Input.row_num]: {
          value: getTotalEmissionValue(this.selectedTab, scope1Input),
          total_duration: scope1Input.total_duration,
          total_month: scope1Input.total_month,
          total: getTotalEmissionValue(this.selectedTab, scope1Input),
          default: false,
          original: scope1Input,
          scopeName: 'scope1',
          category: 1,
          detail_ratio: scope1Input.detail_ratio,
          title: scope1Input?.pattern_nm.includes('.') ? scope1Input?.pattern_nm.split('.')[1] : scope1Input?.pattern_nm,
          label: 'SCOPE 1',
          scope: 1,
          rangeX: 'SCOPE 1'
        },
      };
      // scope 2
      const scope2Input = data.scope_2; // Object
      values.push(getTotalEmissionValue(this.selectedTab, scope2Input));
      result.scope2 = {
        [scope2Input.row_num]: {
          ...result.scope2[scope2Input.row_num],
          // value: isTypeYearly ? scope2Input.total_duration : scope2Input.total_month,
          value:getTotalEmissionValue(this.selectedTab, scope2Input),
          total_duration: scope2Input.total_duration,
          total_month: scope2Input.total_month,
          total: getTotalEmissionValue(this.selectedTab, scope2Input),
          default: false,
          original: scope2Input,
          scopeName: 'scope2',
          category: 1,
          detail_ratio: scope2Input.detail_ratio,
          title: scope2Input?.pattern_nm,
          label: 'SCOPE 2',
          scope: 2,
          rangeX: 'SCOPE 2'
        },
      };
      // scope 3
      const scope3Input = data.scope_3; // Array
      Array.from(scope3Input).forEach((v, i) => {
        const rowNum = v.row_num;
        // values.push(isTypeYearly ? v.total_duration : v.total_month);
        values.push(getTotalEmissionValue(this.selectedTab, v))
        result.scope3 = {
          ...result.scope3,
          [rowNum]: {
            ...result.scope3[rowNum],
            // value: isTypeYearly ? v.total_duration : v.total_month,
            value:getTotalEmissionValue(this.selectedTab, v),
            total_duration: v.total_duration,
            total_month: v.total_month,
            total: getTotalEmissionValue(this.selectedTab, v),
            default: false,
            original: v,
            scopeName: 'scope3',
            category: i + 1,
            detail_ratio: v.detail_ratio,
            title: v?.pattern_nm,
            label: `SCOPE 3`,
            scope: 3,
            rangeX: 'SCOPE 3' + i
          },
        };
      });
      // scope 4
      if (data.scope_4) {
        const scope4Input = data.scope_4; // Object
        // values.push(isTypeYearly ? scope4Input.total_duration : scope4Input.total_month);
        values.push(getTotalEmissionValue(this.selectedTab, scope4Input));
        result.scope4 = {
          ...result.scope4,
          [scope4Input.row_num]: {
            ...result.scope4[scope4Input.row_num],
            // value: isTypeYearly ? scope4Input.total_duration : scope4Input.total_month,
            value: getTotalEmissionValue(this.selectedTab, scope4Input),
            total_duration: scope4Input.total_duration,
            total_month: scope4Input.total_month,
            total: getTotalEmissionValue(this.selectedTab, scope4Input),
            default: false,
            original: scope4Input,
            scopeName: 'scope4',
            detail_ratio: scope4Input.detail_ratio,
            category: 1,
            credit: scope4Input.credit ? scope4Input.credit : [],
            title: scope4Input?.pattern_nm,
            label: 'OTHER 2',
            scope: 4,
            rangeX: 'SCOPE 4'
          },
        };
      }

      let valuesCredit = data.scope_4.credit ? data.scope_4.credit.map(item => item.total_emission) : []

      return [result, Math.max(...[...values,...valuesCredit]), Math.min(...[...values,...valuesCredit]), [...values,...valuesCredit]];
    },
    preparePercent(scopeName, data, max, min) {
      return Object.values(data).map((item, index) => {
        return {
          tooltip:
            scopeName === 'scope1'
              ? scope1Tooltip[index]
              : scopeName === 'scope2'
              ? scope2Tooltip[index]
              : scopeName === 'scope3' ? scope3Tooltip[index] : scope3Tooltip[16],
          ...calcCategoryPercent(item, max, min),
        };
      });
    },
    async updateCompareEmissionsByCategory(isUpdate) {
      this.dataCacheCompare = {};
      let paramsStore = this.getParamsDurationOfStore();
      const firstYear = 2017;
      if (!isUpdate || parseInt(paramsStore.year) === firstYear) {
        this.chartData = this.chartData.map(item => {
          const { pastDurations, ...rest } = item;
          return rest;
        });
        // this.chartData = this.chartData.filter(v => Number(formatValue(v.value)) !== 0);
        return;
      }
      paramsStore.year = paramsStore.year - 1;
      let params = {
        type: this.selectedTab === 1 || this.selectedTab === 2 ? this.selectedTab + 1 : this.selectedTab === 3 ? 1 : 0,
        branch_id: this.branchIds,
        contractor_id: this.$store.state.userData.contractor,
        ...paramsStore,
      };
      // await this.getChartData();
      getEmissionsByCategory(params)
        .then((res) => {
          this.dataCacheCompare = res;
          this.handleDataCompareUpdate(this.getType);
        })
        .catch((err) => {
          console.warn(err);
        });
    },
    combineData(mainData, compareData) {
      return mainData.map((data, index) => ({
        ...data,
        pastDurations: compareData[index] || {},
      }));
    }
  },
};
</script>
